import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const NavigationBarContainer = styled(Box)({
  width: '60px',
  backgroundColor: 'rgba(128, 128, 128, 0.3)',
  backdropFilter: 'blur(20px)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px 0',
  borderRadius: '24px',
  position: 'fixed',
  left: '300px',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 10,
});

const NavButton = styled(IconButton)(({ active }) => ({
  margin: '15px 0',
  width: '50px',
  height: '50px',
  padding: '5px',
  backgroundColor: active ? 'rgba(255, 255, 255, 0.2)' : 'transparent',
  borderRadius: '50%',
  transition: 'background-color 0.3s, transform 0.3s',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    transform: 'scale(1.1)',
  },
  '& img': {
    width: '100%',
    height: '100%',
    filter: active ? 'brightness(1.2)' : 'none',
  },
}));

const NavigationBar = ({ onReturnToDashboard }) => {
  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  return (
    <NavigationBarContainer>
      <NavButton onClick={onReturnToDashboard}>
        <ArrowBackIcon style={{ color: 'white' }} />
      </NavButton>
      <NavButton active={activeButton === 'chat'} onClick={() => handleButtonClick('chat')}>
        <img src="/images/navigationbar/chat_agt_logo.png" alt="Chat AGT" />
      </NavButton>
      <NavButton active={activeButton === 'pivot'} onClick={() => handleButtonClick('pivot')}>
        <img src="/images/navigationbar/pivot_logo.png" alt="Pivot" />
      </NavButton>
      <NavButton active={activeButton === 'dote'} onClick={() => handleButtonClick('dote')}>
        <img src="/images/navigationbar/dote_logo.png" alt="Dote" />
      </NavButton>
      <NavButton active={activeButton === 'tima'} onClick={() => handleButtonClick('tima')}>
        <img src="/images/navigationbar/tima_logo.png" alt="TIMA" />
      </NavButton>
      <NavButton active={activeButton === 'win'} onClick={() => handleButtonClick('win')}>
        <img src="/images/navigationbar/win_logo.png" alt="Win" />
      </NavButton>
    </NavigationBarContainer>
  );
};

export default NavigationBar;