import React from 'react';
import { Typography, Paper, Button, Grid } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

// Datos de ejemplo para el gráfico
const data = [
  { name: 'Día 1', pasos: 4000 },
  { name: 'Día 2', pasos: 3000 },
  { name: 'Día 3', pasos: 5000 },
  { name: 'Día 4', pasos: 4500 },
  { name: 'Día 5', pasos: 6000 },
  { name: 'Día 6', pasos: 5500 },
  { name: 'Día 7', pasos: 7000 },
];

function TIMA() {
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        TIMA
      </Typography>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Análisis de Precisión
        </Typography>
        <div style={{ height: 400, marginTop: 3 }}>
          <LineChart
            width={600}
            height={300}
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="pasos" stroke="#8884d8" activeDot={{ r: 8 }} />
          </LineChart>
        </div>
        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item>
            <Button variant="contained" color="primary">
              ¿Cuándo conseguiré mi objetivo?
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary">
              ¿Qué tan precisas son mis acciones?
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary">
              ¿Qué es TIMA?
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default TIMA;