import React, { useState } from 'react';
import { Box, Typography, TextField, IconButton, Fade } from '@mui/material';
import { styled } from '@mui/system';
import ChatView from './ChatView';
import NavigationBar from './NavigationBar';
import PerspectiveCarousel from './PerspectiveCarousel';

const FixedContainer = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
});

const StyledWindowBar = styled(Box)({
  width: '60%',
  height: '70vh',
  maxWidth: '1200px',
  maxHeight: '800px',
  backgroundColor: 'rgba(128, 128, 128, 0.3)',
  backdropFilter: 'blur(20px)',
  color: 'white',
  padding: '20px',
  borderRadius: '24px',
  fontFamily: "'Inter', sans-serif",
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  position: 'relative',
});

const TopBar = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '20px',
});

const ContentArea = styled(Box)({
  flexGrow: 1,
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
});

const IAContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  gap: '40px',
  marginTop: '20px',
  flexWrap: 'wrap',
});

const IAImage = styled('img')({
  width: '90px',
  height: '90px',
  cursor: 'pointer',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.1)',
    boxShadow: '0 0px 0px rgba(0, 0, 0, 0.2)',
  },
});

const WhiteTitle = styled(Typography)({
  color: 'white',
  fontWeight: 600,
});

const ChatInputContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '90%',
  maxWidth: '1200px',
  marginTop: '20px',
});

const ChatInputField = styled(TextField)({
  width: '80%',
  backgroundColor: 'rgba(128, 128, 128, 0.3)',
  backdropFilter: 'blur(20px)',
  borderRadius: '24px',
  marginTop: '25px',
  padding: '10px',
  '& .MuiInputBase-input': {
    color: 'white',
    marginTop: '2.5px',
    padding: '10px 0',
    height: '1em',
  },
  '& .MuiInputBase-root': {
    '&:before, &:after': {
      borderBottom: 'none !important',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
});

const ChatIconButton = styled(IconButton)({
  marginTop: '25px',
  width: '60px',
  height: '60px',
  padding: 0,
  transition: 'background-color 0.3s, transform 0.3s',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    transform: 'scale(1.1)',
  },
});

const Dashboard = ({ isDarkMode }) => {
  const [currentView, setCurrentView] = useState('home');
  const [chatInput, setChatInput] = useState('');

  const handleIAClick = (iaName) => {
    console.log(`${iaName} clicked`);
  };

  const handleChatSubmit = (e) => {
    e.preventDefault();
    if (chatInput.trim()) {
      setCurrentView('chat');
    }
  };

  const handleReturnToDashboard = () => {
    setCurrentView('home');
    setChatInput('');
  };

  return (
    <FixedContainer>
      <StyledWindowBar>
        <TopBar>
          <WhiteTitle variant="h6" sx={{ textAlign: 'center' }}>
            {currentView === 'home' ? 'Mi Startup' : 'Chat con AGT'}
          </WhiteTitle>
        </TopBar>
        <ContentArea>
          <Fade in={currentView === 'home'} timeout={300}>
            <Box sx={{ display: currentView === 'home' ? 'flex' : 'none', flexDirection: 'column', height: '100%' }}>
              <PerspectiveCarousel />
              <WhiteTitle variant="h6" sx={{ marginTop: '1px', textAlign: 'center' }}>
                Mis IA
              </WhiteTitle>
              <IAContainer>
                <IAImage src="/images/applogo/pivot_logo.png" alt="IA 1" onClick={() => handleIAClick('IA 1')} />
                <IAImage src="/images/applogo/dote_logo.png" alt="IA 2" onClick={() => handleIAClick('IA 2')} />
                <IAImage src="/images/applogo/tima_logo.png" alt="IA 3" onClick={() => handleIAClick('IA 3')} />
                <IAImage src="/images/applogo/win_logo.png" alt="IA 4" onClick={() => handleIAClick('IA 4')} />
              </IAContainer>
            </Box>
          </Fade>
          <Fade in={currentView === 'chat'} timeout={300}>
            <Box sx={{ display: currentView === 'chat' ? 'flex' : 'none', height: '100%', flexDirection: 'column' }}>
              <ChatView initialMessage={chatInput} />
            </Box>
          </Fade>
        </ContentArea>
      </StyledWindowBar>
      {currentView === 'home' && (
        <ChatInputContainer component="form" onSubmit={handleChatSubmit}>
          <ChatInputField
            fullWidth
            variant="standard"
            placeholder="Pregúntame lo que sea"
            value={chatInput}
            onChange={(e) => setChatInput(e.target.value)}
          />
          <ChatIconButton type="submit">
            <img src="/images/applogo/chat_agt_logo.png" alt="Chat AGT" style={{ width: '100%', height: '100%' }} />
          </ChatIconButton>
        </ChatInputContainer>
      )}
      {currentView === 'chat' && (
        <NavigationBar onReturnToDashboard={handleReturnToDashboard} />
      )}
    </FixedContainer>
  );
};

export default Dashboard;