import React, { useState, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

import Sidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';
import ChatAGT from './components/ChatAGT';
import TIMA from './components/TIMA';

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkMode = useCallback(() => {
    setIsDarkMode((prevMode) => !prevMode);
    console.log("Dark mode toggled:", !isDarkMode); // Debug log
  }, [isDarkMode]);

  const theme = createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
      primary: {
        main: '#007AFF',
      },
    },
    typography: {
      fontFamily: 'Inter, sans-serif',
      fontWeightMedium: 500,
      fontWeightRegular: 400,
      fontWeightSemiBold: 600,
      fontWeightBold: 700,
    },
  });

  const backgroundImage = isDarkMode
    ? "url('/images/background/backgroundapp-night.png')"
    : "url('/images/background/backgroundapp-day.png')";

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Box sx={{
          display: 'flex',
          minHeight: '100vh',
          backgroundImage: backgroundImage,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          transition: 'background-image 0.5s ease-in-out',
        }}>
          <Sidebar 
            open={sidebarOpen} 
            onClose={() => setSidebarOpen(false)}
            onToggleDarkMode={toggleDarkMode}
            isDarkMode={isDarkMode}
          />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Routes>
              <Route path="/" element={<Dashboard isDarkMode={isDarkMode} />} />
              <Route path="/chat-agt" element={<ChatAGT />} />
              <Route path="/tima" element={<TIMA />} />
            </Routes>
          </Box>
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;
