import React, { useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const CarouselContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: '400px',
  position: 'relative',
});

const CardsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '300px',
  width: '100%',
  position: 'relative',
  perspective: '1000px',
});

const Card = styled(Box)(({ position }) => ({
  position: 'absolute',
  width: '200px',
  height: '250px',
  transition: 'all 0.5s ease',
  transformStyle: 'preserve-3d',
  ...(position === 'center' && {
    zIndex: 3,
    transform: 'scale(1.1) translateZ(0)',
  }),
  ...(position === 'left' && {
    zIndex: 2,
    transform: 'translateX(-50%) scale(0.9) translateZ(-100px) rotateY(30deg)',
  }),
  ...(position === 'right' && {
    zIndex: 2,
    transform: 'translateX(50%) scale(0.9) translateZ(-100px) rotateY(-30deg)',
  }),
  ...(position === 'far-left' && {
    zIndex: 1,
    transform: 'translateX(-100%) scale(0.8) translateZ(-200px) rotateY(30deg)',
  }),
  ...(position === 'far-right' && {
    zIndex: 1,
    transform: 'translateX(100%) scale(0.8) translateZ(-200px) rotateY(-30deg)',
  }),
}));

const CardContent = styled(Box)({
  width: '100%',
  height: '100%',
  padding: '15px',
  borderRadius: '20px',
  overflow: 'hidden',
  backgroundColor: 'rgba(128, 128, 128, 0.3)',
  backdropFilter: 'blur(12px)',
  border: '1px solid rgba(255, 255, 255, 0.2)',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const CardImage = styled('img')({
  width: '100%',
  height: 'auto',
  objectFit: 'contain',
  marginBottom: '10px',
});

const NavigationContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100px',
  height: '50px',
  marginTop: '10px',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(20px)',
  borderRadius: '25px',
  border: '1px solid rgba(255, 255, 255, 0.2)',
});

const NavigationButton = styled(IconButton)({
  color: 'white',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
});

const PerspectiveCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(2);

  const cards = [
    { image: '/images/notification/data.png', title: 'Privacidad' },
    { image: '/images/notification/join_pro.png', title: 'Únete a Pro' },
    { image: '/images/notification/hello.png', title: 'Bienvenido a AGT' },
    { image: '/images/notification/agt_chat.png', title: 'AGT es Omnipresente' },
    { image: '/images/notification/day_night.png', title: 'Cambia el fondo' },
  ];

  const getCardPosition = (index) => {
    const diff = index - activeIndex;
    if (diff === 0) return 'center';
    if (diff === 1 || diff === -4) return 'right';
    if (diff === -1 || diff === 4) return 'left';
    if (diff === 2 || diff === -3) return 'far-right';
    return 'far-left';
  };

  const handlePrevious = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + cards.length) % cards.length);
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % cards.length);
  };

  return (
    <CarouselContainer>
      <CardsContainer>
        {cards.map((card, index) => (
          <Card key={index} position={getCardPosition(index)}>
            <CardContent>
              <CardImage src={card.image} alt={card.title} />
              <Typography variant="subtitle2" color="white" align="center">
                {card.title}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </CardsContainer>
      <NavigationContainer>
        <NavigationButton onClick={handlePrevious}>
          <ArrowBackIosNewIcon />
        </NavigationButton>
        <NavigationButton onClick={handleNext}>
          <ArrowForwardIosIcon />
        </NavigationButton>
      </NavigationContainer>
    </CarouselContainer>
  );
};

export default PerspectiveCarousel;