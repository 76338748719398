import React, { useState } from 'react';
import { Box, ListItem, Typography, Switch, Slide, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const StyledBox = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 40px)',
  maxWidth: '760px',
  height: 'calc(100vh - 40px)',
  backgroundColor: 'rgba(128, 128, 128, 0.30)',
  backdropFilter: 'blur(20px)',
  color: 'white',
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  top: '20px',
  left: '20px',
  zIndex: 1200,
  borderRadius: '46px',
  overflow: 'hidden',
  fontFamily: "'Inter', sans-serif",
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '46px',
    border: '1px solid transparent',
    background: 'linear-gradient(to bottom, rgba(255,255,255,0.4) 0%, rgba(255,255,255,0) 41%, rgba(255,255,255,0) 57%, rgba(255,255,255,0.1) 100%)',
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
  },
}));

const SectionTitle = styled(Typography)({
  fontSize: '17px',
  fontWeight: 600,
  marginBottom: '8px',
  marginTop: '16px',
  paddingLeft: '20%',
  fontFamily: "'Inter', sans-serif",
});

const ButtonGroup = styled(Box)({
  width: '60%',
  margin: '0 auto',
  backgroundColor: 'rgba(0, 0, 0, 0.30)',
  borderRadius: '19px',
  overflow: 'hidden',
  marginBottom: '16px',
});

const StyledListItem = styled(ListItem)({
  padding: '12px 16px',
  justifyContent: 'flex-start',
  '&:not(:last-child)': {
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  },
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
});

const ButtonText = styled(Typography)({
  fontWeight: 500,
  fontSize: '16px',
  fontFamily: "'Inter', sans-serif",
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale',
});

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#65C466',
        opacity: 1,
        border: 0,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#39393D',
    opacity: 1,
  },
}));

const ToggleButton = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  top: '40px',
  left: '40px',
  zIndex: 1300,
  color: 'white',
  backgroundColor: 'rgba(250, 250, 250, 0.2)',
  '&:hover': {
    backgroundColor: 'rgba(250, 250, 250, 0.4)',
  },
}));

const Sidebar = ({ open, onClose, onToggleDarkMode, isDarkMode }) => {
  const [isOpen, setIsOpen] = useState(open);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    if (onClose && isOpen) {
      onClose();
    }
  };

  const handleDarkModeToggle = () => {
    onToggleDarkMode();
    console.log("Dark mode switch toggled in Sidebar"); // Debug log
  };

  return (
    <>
      <ToggleButton onClick={toggleSidebar}>
        {isOpen ? <CloseIcon /> : <MenuIcon />}
      </ToggleButton>
      <Slide direction="right" in={isOpen} mountOnEnter unmountOnExit>
        <StyledBox>
          <Typography variant="h4" sx={{ fontSize: '34px', fontWeight: 700, textAlign: 'center', mb: 3, fontFamily: "'Inter', sans-serif" }}>
            Mi Startup
          </Typography>

          <SectionTitle>Mis Herramientas</SectionTitle>
          <ButtonGroup>
            <StyledListItem button>
              <ButtonText>Chat con AGT</ButtonText>
            </StyledListItem>
            <StyledListItem button>
              <ButtonText>TIMA</ButtonText>
            </StyledListItem>
            <StyledListItem button>
              <ButtonText>Notas</ButtonText>
            </StyledListItem>
          </ButtonGroup>

          <SectionTitle>Mi Cuenta</SectionTitle>
          <ButtonGroup>
            <StyledListItem button>
              <ButtonText>Información Personal</ButtonText>
            </StyledListItem>
            <StyledListItem button>
              <ButtonText>Privacidad y Seguridad</ButtonText>
            </StyledListItem>
            <StyledListItem button>
              <ButtonText>Mis Pagos y Suscripciones</ButtonText>
            </StyledListItem>
            <StyledListItem button>
              <ButtonText>Ayuda</ButtonText>
            </StyledListItem>
          </ButtonGroup>

          <SectionTitle>Personalización</SectionTitle>
          <ButtonGroup>
            <StyledListItem button>
              <ButtonText>Cambiar Fondo</ButtonText>
            </StyledListItem>
            <StyledListItem sx={{ justifyContent: 'space-between' }}>
              <ButtonText>Tema Oscuro</ButtonText>
              <CustomSwitch 
                checked={isDarkMode}
                onChange={handleDarkModeToggle}
                inputProps={{ 'aria-label': 'cambiar tema' }}
              />
            </StyledListItem>
          </ButtonGroup>

          <ButtonGroup sx={{ backgroundColor: 'rgba(255, 59, 48, 0.2)', mt: 'auto' }}>
            <StyledListItem button>
              <ButtonText sx={{ color: '#FF3B30' }}>Cerrar sesión</ButtonText>
            </StyledListItem>
          </ButtonGroup>
        </StyledBox>
      </Slide>
    </>
  );
};

export default Sidebar;