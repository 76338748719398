import React, { useState } from 'react';
import { Paper, Typography, TextField, Button, Box } from '@mui/material';

function ChatAGT() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const handleSend = () => {
    if (input.trim()) {
      setMessages([...messages, { text: input, sender: 'user' }]);
      setInput('');
      // Aquí puedes agregar la lógica para obtener una respuesta del chatbot
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 64px)' }}>
      <Typography variant="h4" gutterBottom>
        Chat AGT
      </Typography>
      <Paper sx={{ flexGrow: 1, overflow: 'auto', p: 2, mb: 2 }}>
        {messages.map((message, index) => (
          <Typography key={index} align={message.sender === 'user' ? 'right' : 'left'}>
            {message.text}
          </Typography>
        ))}
      </Paper>
      <Box sx={{ display: 'flex' }}>
        <TextField
          fullWidth
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Escribe un mensaje..."
          variant="outlined"
          sx={{ mr: 2 }}
        />
        <Button variant="contained" color="primary" onClick={handleSend}>
          Enviar
        </Button>
      </Box>
    </Box>
  );
}

export default ChatAGT;