import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, TextField, IconButton, Avatar, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';

const AppContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  width: '100%',
  overflow: 'hidden',
});

const ChatContainer = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
  overflow: 'hidden',
});

const ChatHeader = styled(Typography)({
  textAlign: 'center',
  marginBottom: '20px',
  fontWeight: 'bold',
});

const MessagesContainer = styled(Box)({
  flexGrow: 1,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  paddingRight: '10px',
});

const MessageBubble = styled(Box)(({ theme, $isUser }) => ({
  maxWidth: '70%',
  padding: '10px 15px',
  marginBottom: '10px',
  backgroundColor: $isUser ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
  borderRadius: '18px',
  alignSelf: $isUser ? 'flex-end' : 'flex-start',
  display: 'flex',
  alignItems: 'center',
}));

const InputContainer = styled(Box)({
  display: 'flex',
  padding: '10px 20px',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  borderRadius: '24px',
  marginTop: 'auto',
});

const StyledInput = styled(TextField)({
  flexGrow: 1,
  '& .MuiInputBase-input': {
    color: 'white',
    marginTop: '2.5px',
    padding: '10px 0',
    height: '1em',
  },
  '& .MuiInputBase-root': {
    display: 'flex',
    alignItems: 'center',
    '&:before, &:after': {
      borderBottom: 'none',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
    '&.Mui-disabled:before': {
      borderBottom: 'none',
    },
  },
  '& .MuiInput-underline:before': {
    borderBottom: 'none',
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottom: 'none',
  },
  '& .MuiInput-underline:after': {
    borderBottom: 'none',
  },
});

const SendButton = styled(IconButton)({
  color: 'white',
});

const LoadingIndicator = styled(CircularProgress)({
  color: 'white',
  marginLeft: '10px',
});

const ChatView = ({ initialMessage, onClose }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isWaitingResponse, setIsWaitingResponse] = useState(false);
  const [currentResponse, setCurrentResponse] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef(null);
  const [userId] = useState('user123'); // En una aplicación real, esto vendría de la autenticación

  useEffect(() => {
    fetchChatHistory();
    if (initialMessage) {
      handleSendMessage(initialMessage);
    }
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    if (currentResponse) {
      const typingInterval = setInterval(() => {
        setMessages(prevMessages => {
          const lastMessage = prevMessages[prevMessages.length - 1];
          if (!lastMessage || lastMessage.isUser) {
            return [...prevMessages, { text: '', isUser: false }];
          }

          if (lastMessage.text.length < currentResponse.length) {
            const nextChar = currentResponse[lastMessage.text.length];
            return [
              ...prevMessages.slice(0, -1),
              { ...lastMessage, text: lastMessage.text + nextChar },
            ];
          } else {
            clearInterval(typingInterval);
            setIsTyping(false);
            setCurrentResponse('');
            return prevMessages;
          }
        });
      }, 50); // Ajusta la velocidad de escritura cambiando el intervalo (en ms)
    }
  }, [currentResponse]);

  const fetchChatHistory = async () => {
    try {
      const response = await axios.get(`http://localhost:3001/api/chat/history/${userId}`);
      setMessages(response.data.history.map(msg => ({
        text: msg.content,
        isUser: msg.role === 'user'
      })));
    } catch (error) {
      console.error('Error al obtener el historial del chat:', error);
    }
  };

  const handleSendMessage = async (messageText) => {
    if (messageText.trim() && !isWaitingResponse) {
      setIsWaitingResponse(true);
      setMessages(prev => [...prev, { text: messageText, isUser: true }]);
      setInput('');
      try {
        const response = await axios.post('http://localhost:3001/api/chat/send', {
          userId,
          message: messageText
        });
        if (response.data && response.data.response) {
          setIsTyping(true);
          setCurrentResponse(response.data.response);
        } else {
          console.error('Respuesta inesperada del servidor:', response.data);
          setMessages(prev => [...prev, { text: "Lo siento, ha ocurrido un error.", isUser: false }]);
        }
      } catch (error) {
        console.error('Error al enviar el mensaje:', error);
        setMessages(prev => [...prev, { text: "Lo siento, ha ocurrido un error.", isUser: false }]);
      } finally {
        setIsWaitingResponse(false);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSendMessage(input);
  };

  return (
    <AppContainer>
      <ChatContainer>
        <ChatHeader variant="h6">Chat con AGT</ChatHeader>
        <MessagesContainer>
          {messages.map((message, index) => (
            <MessageBubble key={index} $isUser={message.isUser}>
              {!message.isUser && (
                <Avatar src="/images/applogo/chat_agt_logo.png" alt="AGT" style={{ marginRight: '10px', alignSelf: 'center' }} />
              )}
              <Typography color="white">{message.text}</Typography>
            </MessageBubble>
          ))}
          <div ref={messagesEndRef} />
        </MessagesContainer>
        <InputContainer component="form" onSubmit={handleSubmit}>
          <StyledInput
            fullWidth
            variant="standard"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Escribe tu mensaje..."
            disabled={isWaitingResponse}
          />
          <SendButton type="submit" disabled={isWaitingResponse}>
            {isWaitingResponse ? (
              <LoadingIndicator size={24} />
            ) : (
              <img src="/images/applogo/chat_agt_logo.png" alt="Send" style={{ width: '24px', height: '24px' }} />
            )}
          </SendButton>
        </InputContainer>
      </ChatContainer>
    </AppContainer>
  );
};

export default ChatView;